<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-01 15:29:17
 * @LastEditors: Lizy
 * @LastEditTime: 2021-12-07 16:20:33
-->
<template>
  <div class="marketing">
    <!--营销活动页版心 -->
    <div class="product" v-for="(item, index) in banner" :key="index">
      <img class="product_img" :src="$utils.picturePrefix(item.image)" alt="" />
    </div>
    <div class="content">
      <div class="sub"></div>
      <div class="marketing_left">
        <div class="godds_list" v-if="list.length > 0">
          <div
            class="item"
            @click="go_detail(item.id)"
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="item_img"><img class="cover" :src="$utils.picturePrefix(item.image)" /></div>
            <div class="godds_list_text">
              <div class="caption ellipsis_one">{{ item.title }}</div>
              <div class="entrance">
                <span class="clock">{{ item.createDate }}</span>
              </div>
            </div>
          </div>
        </div>
        <empty
              v-else
              description="暂无活动"
            ></empty>
      </div>
    </div>
    <pagination
      ref="pagination"
      :total="total"
      @change="pagin_change"
      v-if="total != 0"
    ></pagination>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
export default {
  components: {
    Pagination,
    Empty
  },
  data() {
    return {
      // 背景
      banner: [],
      //  营销活动页展示数据
      list: [],
      num: 9,
      page: 1,
      total: 0,
      type: 5
    };
  },
  computed: {},
  watch: {},
  created() {
    this.query_getlist();
    this.query_banner();
  },
  mounted() {
    this.$refs.pagination.setData(this.page, this.num);
  },
  methods: {
    bannerHeight() {
        let width = window.innerWidth;
        let getClass = document.getElementsByClassName("product")[0];
        
        // eslint-disable-next-line no-self-assign
        width < 1200 ? (width = 1200) : (width = width);
        let height = width / 1920 * 360;
        console.log(getClass,height);
        return getClass.style.height = height + "px";
    },
    //  @click 点击后跳转至营销活动详情页
    go_detail(id) {
      let routeData = this.$router.resolve({
        path: `/marketing/detail?id=${id}`,
      });
      window.open(routeData.href, "_blank");
      // this.$router.push(`/marketing/detail?id=${id}`);
    },
    // 营销活动
    query_getlist() {
      this.$api.getMarketing({ num: this.num, page: this.page }).then(res => {
        let {
          code,
          data: { records, total }
        } = res;

        if (code == 200) {
          this.total = total;
          this.list = records;
          // this.$refs.pagination.setData(this.page, this.num);
          // nextTick(() => {
          //   //
          // });
        }
      });
    },
    //  背景图
    query_banner() {
      this.$api.getBanner({ type: this.type }).then(res => {
        let { code, data } = res;
        if (code == 200) {
          this.banner = data;
          setTimeout(() => {
            this.bannerHeight();
          }, 0);
        }
      });
    },
    // 分页方法
    pagin_change(page, pageSize) {
      this.page = page;
      this.num = pageSize;
      this.query_getlist();
    }
  }
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
