<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-06 08:51:14
 * @LastEditors: Zhongyu
 * @LastEditTime: 2021-09-24 18:08:16
-->
<template>
  <div class="empty">
    <img class="" src="../../assets/img/common/img-empty-icon.png" />
    <el-empty :image-size="imageSize" :description="description"></el-empty>
  </div>
</template>

<script>
export default {
  props: {
    imageSize: {
      type: Number,
      default: 1
    },
    description: {
      type: String,
      default: "-"
    }
  }
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
