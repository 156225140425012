<!--
 * @Description: 分页组件
 * @Autor: Zhongyu
 * @Date: 2021-09-14 14:55:28
 * @LastEditors: Zhongyu
 * @LastEditTime: 2021-09-26 15:24:48
-->
<template>
  <div class="default-pagination">
    <el-button :disabled="page <= 1" @click="handlePaginationChange(1)"
      >首页</el-button
    >
    <!-- 分页按钮 start -->
    <el-pagination
      background
      :current-page="page"
      :page-size="pageSize"
      layout="prev, pager, next"
      :total="total || 1"
      :pager-count="5"
      @current-change="handlePaginationChange"
    >
    </el-pagination>
    <!-- 分页按钮 end -->
    <el-button
      :disabled="page >= sumPage"
      @click="handlePaginationChange(sumPage)"
      >尾页</el-button
    >
  </div>
</template>

<script>
export default {
  name: "Pagination",

  props: {
    // 总条数
    total: {
      type: Number,
      default: 0
    }
  },
  data() {
    let pageSize = 10;
    return {
      page: 1, // 页码
      pageSize, // 每页条数
      sumPage: this.total / pageSize
    };
  },
  watch: {
    total(val) {
      let pageSize = this.pageSize;
      let page = parseInt(val / pageSize);
      if (val % pageSize != 0) {
        page++;
      }
      this.sumPage = page;
    }
  },
  methods: {
    /**
     * 提供父层调用更新页码和每页条数
     * @page 页码
     * @pageSize 每页条数
     */
    setData(page, pageSize) {
      if (page) {
        this.page = page;
      }
      let total = this.total;
      let sumPage = parseInt(total / pageSize);
      if (total % pageSize != 0) {
        sumPage++;
      }
      this.sumPage = sumPage;
      // 筛选传入和每页条数是否在每页条数组中
      this.pageSize = pageSize;
    },

    /**
     * 页码改变的回调，参数是改变后的页码及每页条数
     * @page 页码
     * @pageSize 每页条数
     */
    handlePaginationChange(page) {
      this.page = page;
      /**
       * 触发父层方法
       */
      this.$emit("change", page, this.pageSize);
    }
  }
};
</script>

<style lang="less">
@import "./index.less";
</style>
